import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CFormCheck } from '@coreui/react';
import InputMask from "react-input-mask";
import Select from 'react-select';
import AxiosInstance from 'src/components/Axios';
import MyToastify from 'src/components/myComponents/MyToactify';
import { useNavigate } from 'react-router-dom';

// Форма для додавання (оновлення) учасника подїй
const SubscriberForm = (props) => {
    // console.log('SubscriberForm props', props);

    const urlApi_1 = "/events";
    const urlApi_2 = "/locations";
    const urlApi_3 = "/specialties";
    const urlApi_4 = "/subscribers";
    const urlRouter = '/subscribers';

    const navigate = useNavigate();
    const popup = MyToastify();
    const popupTextPostSuccess = "Учасника створено";
    const popupTextPostWarning = "Учасника не створено";
    const popupTextPutSuccess = "Учасника оновлено";
    const popupTextPutWarning = "Учасника не оновлено";
    const popupTextPhoneWarning = "Заповніть номер телефону";

    // Форма навчання
    const studyTypeArr = [
        // { value: 'Самостійне навчання вдома', label: 'Самостійне навчання вдома' },
        { value: 'Відвідування лекцій на підприємстві', label: 'Відвідування лекцій на підприємстві' },
    ];

    const [name, setName] = useState(props?.data?.name ? props.data.name : "");
    const [email, setEmail] = useState(props?.data?.email ? props.data.email : "");
    const [phone, setPhone] = useState(props?.data?.phone ? props.data.phone : "");
    const [phoneToApi, setPhoneToApi] = useState("");
    const [studyType, setStudyType] = useState(studyTypeArr[0]);
    const [selectedStydyType, setSelectedStydyType] = useState(null);
    const [eventsToApi, setEventsToApi] = useState([]); // Список подій для учасника в api
    const [eventsList, setEventsList] = useState([]); // Список всіх подій
    // const [subscriberEventsList, setSubscriberEventsList] = useState([]);
    const [subscriberEventsList, setSubscriberEventsList] = useState(props?.data?.events ? [{ label: props.data.events[0].name, value: props.data.events[0].id }] : ""); // Список подій для учасника
    const [ipn, setIpn] = useState(props?.data?.ipn ? props.data.ipn : "");
    const [isNotIpn, setIsNotIpn] = useState(props?.data?.pasportNumber ? true : false);
    const [pasportCode, setPasportCode] = useState(props?.data?.pasportCode ? props.data.pasportCode : "");
    const [pasportNumber, setPasportNumber] = useState(props?.data?.pasportNumber ? props.data.pasportNumber : "");
    const [location, setLocation] = useState(props?.data?.location ? { label: props.data.location.name, value: props.data.location.id } : ""); // Підрозділ
    const [locationList, setLocationList] = useState([]);
    const [subSpecialties, setSubSpecialties] = useState([]); // Список допоміжних напрямків
    const [specialtyToApi, setSpecialtyToApi] = useState(""); // Головна спеціальність для відправлення в Api
    const [specialtiesToApi, setSpecialtiesToApi] = useState([]); // Додаткові спеціальності для відправлення в Api
    const [specialty, setSpecialty] = useState(""); // Спеціальність
    const [specialtiesList, setSpecialtiesList] = useState([]); // Всі спеціальності
    const laborProtectionLabel = "Напрямок Охорона праці"; // Напрямок "Охорона праці"
    const [laborProtection, setLaborProtection] = useState([]); // Масив для "Основний напрямок"
    const [selectedLaborProtection, setSelectedLaborProtection] = useState(null); // Вибраний "Основний напрямок"
    const [laborProtectionId, setLaborProtectionId] = useState(""); // id для "Напрямок Охорона праці"
    const [isDisabledBtnSave, setIsDisabledBtnSave] = useState(false);

    const style = {
        maxHeight: '80vh',
        overflowY: "auto",
        paddingRight: "10px",
    };

    // Визначення стилю в залежності від props.type
    const styleContainer = props.page === "events" ? style : {};

    // Функція для стартового заповнення спеціальностей "Напрямком Охорона праці" для відправлення на api
    const setSpecialtiesToApiLaborProtection = () => {
        if (laborProtectionId) {
            setSpecialtiesToApi([`/api/specialties/${laborProtectionId}`])
        };
    };

    // Заповнити в спеціальності "Напрямок Охорона праці" для відправлення на api
    useEffect(() => {
        // console.log('laborProtectionId', laborProtectionId);
        setSpecialtiesToApiLaborProtection();
    }, [laborProtectionId]);

    // Заповнити масив для select "Основний напрямок" та знайти id для "Напрямок Охорона праці"
    useEffect(() => {
        if (specialtiesList.length > 0) {
            let arr = specialtiesList.filter(specialty => specialty.label === laborProtectionLabel);
            if (arr.length > 0) {
                setLaborProtection(arr);
                setLaborProtectionId(arr[0].value);
            };
        };
    }, [specialtiesList]);

    // Заповнити селект для "Основний напрямок"
    useEffect(() => {
        // console.log('laborProtection', laborProtection);
        if (laborProtection.length > 0) {
            setSelectedLaborProtection(laborProtection[0]);
        };
    }, [laborProtection]);

    // Заповнити дані з "Напрямок Охорона праці" в specialtiesToApi (Додаткові спеціальності для відправлення в Api)
    useEffect(() => {
        // console.log('laborProtectionId', laborProtectionId);
        setSpecialtiesToApiLaborProtection();
    }, [laborProtectionId]);

    useEffect(() => {
        getEventsList();
        getLocations();
        getSpecialties();
    }, []);

    // Підставити за замовчуванням подію
    useEffect(() => {
        if (eventsList.length > 0 && props?.eventId) {
            const selectedEvent = eventsList.find(event => event.value === props.eventId);
            if (selectedEvent) {
                setSubscriberEventsList([selectedEvent]);
            };
        };
    }, [props.eventId, eventsList]);

    // Заповнити спеціальності (напрямки)
    useEffect(() => {
        // Перевірка, чи є specialtiesList і дані про спеціальність
        if (specialtiesList.length > 0 && props.data?.specialty) {
            // Основний напрямок
            const selectedSpecialty = specialtiesList.find(spec => spec.value === props.data.specialty.id);

            if (selectedSpecialty) {
                const specialtyData = {
                    label: props.data.specialty.name,
                    value: props.data.specialty.id,
                    specialties: selectedSpecialty.specialties || [],
                    countSub: selectedSpecialty.countSub || 0,
                };
                setSpecialty(specialtyData);
                setSpecialtyToApi(`/api/specialties/${props.data.specialty.id}`);

                // Обнуляємо та перезаповнюємо subSpecialties
                const newSubSpecialties = new Array(specialtyData.countSub).fill(null);

                // Якщо є додаткові напрямки в props.data, заповнюємо їх
                if (props?.data?.specialties) {
                    let subSpecialtiesData = [];

                    // В допоміжні напрямки не додавати "Напрямок Охорона праці"
                    if (laborProtectionId) {
                        subSpecialtiesData = props.data.specialties
                            .filter(subSpec => subSpec.id !== laborProtectionId)
                            .map(subSpec => ({
                                label: subSpec.name,
                                value: subSpec.id,
                            }));
                    } else {
                        subSpecialtiesData = props.data.specialties.map(subSpec => ({
                            label: subSpec.name,
                            value: subSpec.id,
                        }));
                    };

                    // Вставляємо дані в новий масив
                    subSpecialtiesData.forEach((subSpecialty, index) => {
                        if (index < newSubSpecialties.length) {
                            newSubSpecialties[index] = subSpecialty;
                        };
                    });

                    const specialtiesToApiData = subSpecialtiesData.map(subSpec => `/api/specialties/${subSpec.value}`);

                    // Підготувати дані для відправлення на api
                    if (laborProtectionId) {
                        setSpecialtiesToApi(
                            prevSpecialtiesToApi => {
                                // Створюємо новий масив без дублікатів, використовуючи Set
                                const updatedSpecialties = [...new Set([...prevSpecialtiesToApi, ...specialtiesToApiData])];
                                return updatedSpecialties;
                            });
                    } else {
                        setSpecialtiesToApi(specialtiesToApiData);
                    };
                };

                setSubSpecialties(newSubSpecialties);
            };
        };
    }, [props.data, specialtiesList, laborProtectionId]);

    // Функція для запиту за подіями
    const getEventsList = async (inputValue) => {
        let rez = await AxiosInstance.get(urlApi_1, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("specialties get response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
        setEventsList(rez);
        return rez;
    };

    // Функція для зміни масиву з доданими подіями
    const handleChangeEvents = (value) => {
        // console.log('handleChangeEvents value', value);
        setSubscriberEventsList(value);
    };

    // Функція для вибору форми навчання
    const handleChangeStydyType = (value) => {
        setSelectedStydyType(value);
    };

    // Заповнити форму навчання
    useEffect(() => {
        if (selectedStydyType) {
            setStudyType(selectedStydyType.value);
        }
    }, [selectedStydyType]);

    // Заповнити список подій для учасника в api
    useEffect(() => {
        // console.log('subscriberEventsList', subscriberEventsList);
        if (subscriberEventsList.length > 0) {
            // Оновлення списку подій у форматі IRI
            const eventsIRIs = subscriberEventsList.map(event => `/api/events/${event.value}`);
            setEventsToApi(eventsIRIs);
        } else {
            setEventsToApi([]);
        };
    }, [subscriberEventsList]);

    // Функція для запиту за підрозділами
    const getLocations = async (inputValue) => {
        let rez = await AxiosInstance.get(urlApi_2, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
        setLocationList(rez);
        return rez;
    };

    // Функція для запиту за спеціальностями
    const getSpecialties = async (inputValue) => {
        let rez = await AxiosInstance.get(urlApi_3, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("get specialties/ response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
                specialties: result.specialties,
                countSub: result?.countSub ? result.countSub : 0
            }));
        });
        setSpecialtiesList(rez);
        return rez;
    };

    // Функція для вибору головного напрямку (спеціальності), створення масиву для додаткових напрямків (спеціальностей) та підготовки даних для відправлення в api
    const handleSpecialtyChange = (value) => {
        setSpecialty(value);
        setSubSpecialties(new Array(value.countSub).fill(null));
        setSpecialtyToApi(`/api/specialties/${value.value}`);
        setSpecialtiesToApi([]);
        setSpecialtiesToApiLaborProtection();
    };

    // Функція для вибору додаткових напрямків (спеціальностей) та підготовки даних для відправлення в api
    const handleSubSpecialtyChange = (index, value) => {
        const updatedSubSpecialties = [...subSpecialties];
        if (value?.value === null) {
            // Якщо вибрано "Відмінити вибір", встановити відповідний елемент у null
            updatedSubSpecialties[index] = null;
        } else {
            updatedSubSpecialties[index] = value;
        };
        setSubSpecialties(updatedSubSpecialties);

        // Оновлення specialtyToApi
        const updatedSpecialtiesToApiToApi = [
            ...updatedSubSpecialties.filter(v => v).map(sub => `/api/specialties/${sub.value}`)
        ];
        if (laborProtectionId) {
            setSpecialtiesToApi([`/api/specialties/${laborProtectionId}`, ...updatedSpecialtiesToApiToApi])
        } else {
            setSpecialtiesToApi(updatedSpecialtiesToApiToApi);
        };
    };

    // Підготувати дані номера телефону для відправлення в api
    useEffect(() => {
        setPhoneToApi(phone.replace(/\D/g, ''));
    }, [phone]);

    // Функція для створення учасника
    const createSubscriber = async (data_response) => {
        setIsDisabledBtnSave(true);
        try {
            const response = await AxiosInstance.post(urlApi_4, data_response);
            // console.log('subscribers post response', response);
            popup.toastifySuccess(popupTextPostSuccess);
            setIsDisabledBtnSave(false);

            // Для сторінки "Учасники"
            if (props.page !== undefined && props.page === "subscribers") {
                navigate(`${urlRouter}/update/${response.data.id}`)
            };

            // Для сторінки "Події"
            if (props.page !== undefined && props.page === "events") {
                props.setIsShowModalForm(false);
            };
        } catch (error) {
            console.error("Помилка створення учасника", error);
            popup.toastifyWarning(popupTextPostWarning);
            setIsDisabledBtnSave(false);
        };
    };

    // Функція для оновлення учасника
    const updateSubscriber = async (data_response) => {
        setIsDisabledBtnSave(true);
        try {
            const response = await AxiosInstance.put(`${urlApi_4}/${props.data.id}`, data_response);
            // console.log('subscribers put response', response);
            popup.toastifySuccess(popupTextPutSuccess);
            setIsDisabledBtnSave(false);

            // На сторінці "Учасники"
            if (props.page !== undefined && props.page === "subscribers") {
                props.setMyData(response.data);
            };

            // На сторінці "Події"
            if (props.page !== undefined && props.page === "events") {
                props.setMyData(response.data);
            };
        } catch (error) {
            console.error("Помилка оновлення учасника", error);
            popup.toastifyWarning(popupTextPutWarning);
            setIsDisabledBtnSave(false);
        };
    };


    // // Для тестування
    // const data_response = {
    //     name: name,
    //     email: email,
    //     phone: phoneToApi,
    //     studyType: studyType.value,
    //     events: eventsToApi,
    //     location: location ? `/api/locations/${location.value}` : "",
    //     specialty: specialtyToApi,
    //     specialties: specialtiesToApi,
    //     ipn: ipn,
    //     pasportCode: pasportCode,
    //     pasportNumber: pasportNumber,
    // };
    // useEffect(() => {
    //     console.log('data_response', data_response);
    // }, [data_response]);

    const handleSubmit = event => {
        event.preventDefault();

        let isFilledSelects = true;
        let warningText = "Заповніть дані:";
        let warningTextArr = [];

        // Додаткова перевірка чи заповнені поля
        if (phoneToApi.length !== 12) {
            warningTextArr.push("номер телефону");
            isFilledSelects = false;
        };
        if (eventsToApi.length == 0) {
            warningTextArr.push("подію");
            isFilledSelects = false;
        };
        if (location == "") {
            warningTextArr.push("підрозділ");
            isFilledSelects = false;
        };
        if (specialty == "") {
            warningTextArr.push("напрямок");
            isFilledSelects = false;
        };

        if (!isFilledSelects) {
            // Вивести повідомлення, які ще поля потрібно заповнити
            popup.toastifyWarning(`${warningText} ${warningTextArr.join(", ")}`);
        } else {
            const data_response = {
                name: name,
                email: email,
                phone: phoneToApi,
                studyType: studyType.value,
                events: eventsToApi,
                location: location ? `/api/locations/${location.value}` : "",
                specialty: specialtyToApi,
                specialties: specialtiesToApi,
            };

            if (!isNotIpn) {
                data_response["ipn"] = ipn;
            };
            if (isNotIpn) {
                data_response["pasportCode"] = pasportCode;
                data_response["pasportNumber"] = pasportNumber;
            };

            // console.log('data_response', data_response);

            if (props.data != undefined) {
                // Оновити учасника
                updateSubscriber(data_response);
            } else {
                // Створити учасника
                createSubscriber(data_response);
            };
        };
    };

    return (
        <div style={styleContainer}>
            <CForm onSubmit={handleSubmit} className="mb-3">
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Ім'я в системі (ПІБ)*</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)}
                        //  autoComplete="off"
                        required />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Email*</CFormLabel>
                    <CFormInput type="email" id="exampleFormControlInput1" value={email} onChange={(e) => setEmail(e.target.value)}
                        //  autoComplete="off"
                        required />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Телефон*</CFormLabel>
                    <div>
                        <InputMask
                            id='phone'
                            className="myForm__input-tel"
                            mask="+38 (999) 999-99-99"
                            placeholder="+38 (___) ___-__-__"
                            maskChar="_"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            // autoComplete="off"
                            required
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Форма навчання</CFormLabel>
                    <Select options={studyTypeArr}
                        // value={selectedStydyType}
                        value={studyType}
                        onChange={(value) => { handleChangeStydyType(value) }} isDisabled required />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Подія*</CFormLabel>
                    <Select
                        value={subscriberEventsList}
                        isMulti
                        name="colors"
                        options={eventsList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value) => handleChangeEvents(value)}
                        placeholder="Виберіть подію"
                    />
                </div>

                {
                    !isNotIpn &&
                    <div className="mb-3">
                        <CFormLabel htmlFor="exampleFormControlInput2">ІПН - Індивідуальний Податковий Номер*</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput1" maxLength={10} value={ipn}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) { // перевіряє, що введені лише цифри
                                    setIpn(value);
                                }
                            }}
                            // autoComplete="off" 
                            required />
                    </div>
                }
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput8" label="Немає ІПН" checked={isNotIpn} onChange={() => setIsNotIpn(!isNotIpn)} />
                </div>
                {
                    isNotIpn &&
                    <>
                        <div>(У випадку ІД картки заповнити тільки "Номер паспорта")</div>
                        <div className="d-flex">
                            <div className="mb-3 me-3">
                                <CFormLabel htmlFor="exampleFormControlInput2">Серія паспорта</CFormLabel>
                                <CFormInput type="text" id="exampleFormControlInput1" maxLength={2} value={pasportCode}
                                    // onChange={(e) => setPasportCode(e.target.value)}
                                    onChange={(e) => {
                                        const value = e.target.value.toUpperCase();
                                        if (/^[A-Za-zА-Яа-яЇїІіЄєҐґ]*$/.test(value)) { // перевіряє, що введені лише букви
                                            setPasportCode(value);
                                        }
                                    }}
                                // autoComplete="off" 
                                />
                            </div>
                            <div className="mb-3">
                                <CFormLabel htmlFor="exampleFormControlInput2">Номер паспорта*</CFormLabel>
                                <CFormInput type="text" id="exampleFormControlInput1" value={pasportNumber}
                                    onChange={(e) => setPasportNumber(e.target.value)} required
                                // autoComplete="off" 
                                />
                            </div>
                        </div>
                    </>
                }

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Підрозділ*</CFormLabel>
                    <Select
                        options={locationList}
                        value={location}
                        onChange={(value) => setLocation(value)}
                        placeholder="Виберіть підрозділ"
                    />
                </div>

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Обов'язковий напрямок</CFormLabel>
                    <Select
                        options={laborProtection}
                        value={selectedLaborProtection}
                        placeholder="Виберіть обов'язковий напрямок"
                        isDisabled
                    />
                </div>

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Основний напрямок*</CFormLabel>
                    <Select
                        options={[...specialtiesList
                            .filter(specialty => specialty.value != laborProtectionId) // не показувати "Напрямок Охорона праці"
                        ]}
                        value={specialty}
                        // onChange={(value) => setSpecialty(value)}
                        onChange={handleSpecialtyChange}
                        placeholder="Виберіть напрямок"
                    />
                </div>

                {
                    (specialty && specialty.countSub > 0) &&
                    Array.from({ length: specialty.countSub }).map((_, index) => {
                        return (
                            <div className="mb-3" key={index}>
                                <CFormLabel htmlFor={`subSpecialty${index}`}>Допоміжний напрямок {index + 1}</CFormLabel>
                                <Select
                                    id={`subSpecialty${index}`}
                                    options={[
                                        { label: 'Відмінити вибір', value: null }, // Опція для скасування вибору
                                        ...specialty.specialties
                                            .filter(specialtyOption =>
                                                !subSpecialties.some(sub => sub && sub.value === specialtyOption.id)
                                            )
                                            .map(specialtyOption => ({
                                                label: specialtyOption.name,
                                                value: specialtyOption.id,
                                            }))
                                    ]}
                                    value={subSpecialties[index]}
                                    onChange={(value) => handleSubSpecialtyChange(index, value)}
                                    placeholder="Виберіть допоміжний напрямок"
                                />
                            </div>
                        );
                    })
                }

                <CButton className="me-2" type="submit" color="success" disabled={isDisabledBtnSave}>Зберегти</CButton>

                {/* Для сторінки події для закривання модального вікна */}
                {
                    props.page === "events" &&
                    <CButton className="" color="secondary" onClick={() => props.setIsShowModalForm(false)}>Закрити</CButton>
                }
            </CForm>

        </div>
    );
};

export default SubscriberForm;