/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import AddProductInfo from 'src/components/product/AddProductInfo';
import ViewProductInfo from 'src/components/product/ViewProductInfo';
import BasicModal from 'src/components/Modal';
import ProductAttributes from 'src/components/product/ProductAttributes';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProductCategories from 'src/components/product/ProductCategories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import WarningWhenEditing from 'src/components/product/WarningWhenEditing';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import ProductRelated from 'src/components/product/ProductRelated';
import LangugesPanel from 'src/components/product/LangugesPanel';
import ProductMedia from 'src/components/product/ProductMedia';
import ProductAvailability from 'src/components/product/ProductAvailability';
import ProductPrice from 'src/components/product/ProductPrice';
import ProductGeneral from 'src/components/product/ProductGeneral';


const urlApi = '/products';
const urlRouter = '/products';
const ApiParams = {
  "g[0]": "product:read",
  "g[1]": "product:admin",
  "g[3]": "product:category",
  "g[4]": "product:attributeItems",
  "g[5]": "product:media",
  "g[6]": "product:language",
};

const cabinetLink = process.env.REACT_APP_SITE_URL;

export async function LoaderProductsUpdate({ params }) {

  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`, { params: ApiParams }).then((response) => {
      // console.log("get /api/products/ response", response);
      return response;
    });
    return res;
  }
  return false;
}

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}

const ProductForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();

  const [MyData, setMyData] = useState(data);

  // оновити сторінку якщо помінялась id 
  useEffect(() => {
    if (data != undefined)
      AxiosInstance.get(`${urlApi}/${data.id}`).then((response) => {
        setName(response.data.name);
        // setDescription(response.data.description);
        // setPrice(response.data.price);
        // setMedia(response.data.media);
        setUpdate(true);
      });

  }, [data]);

  useEffect(() => {
    // console.log('data', data);
    setMyData(data);
  }, [data]);


  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [showModal, setShowModal] = useState(false);
  const [update, setUpdate] = useState(false);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Товар збережено";

  // Відкрити/закрити вікно з попередженням про редагування товару
  const [openWarning, setOpenWarning] = useState(true);

  const handleSubmit = (event, dataFromComponent) => {
    event.preventDefault();
    // console.log('dataFromComponent', dataFromComponent);
    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, dataFromComponent, { params: ApiParams }).then((response) => {
        // console.log('products/id/ put response', response);
        if (response.status === 200) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextSuccess);
          setMyData(response.data);
        }
      });
    } else {
      dataFromComponent['price'] = 0;
      dataFromComponent['oldPrice'] = 0;
      // dataFromComponent['oldPrice'] = 0;

      const response = AxiosInstance.post(`${urlApi}`, dataFromComponent, { params: ApiParams }).then((response) => {
        // console.log('post product response', response);
        if (response.status === 201) {
          return navigate(`${urlRouter}/update/${response.data.id}`);
        }
      });
    };
  };

  const actionShowModal = (rez, isUpdate = false) => {
    setShowModal(rez);
    if (!rez && isUpdate)
      setUpdate(true);
  };

  // Функція для закриття модального вікна з попередженням про редагування товару
  const handleCloseWarning = () => {
    // Закрити модальне з попередженням
    setOpenWarning(false);
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />
      <div className='row'>
        <div className='col'>
          <h1 className="mb-3">{name}</h1>
        </div>
        <div className='col d-flex justify-content-end'>
          {data !== undefined && <LangugesPanel product={data} />}
        </div>

        {/* Модальне вікно з попередженням про редагування товару */}
        {data !== undefined && <WarningWhenEditing openWarning={openWarning} handleCloseWarning={handleCloseWarning} />}

        {data !== undefined &&
          <>
            <Row>
              <Col sm={8}>
                <h1 className="mb-3">{name}</h1>
              </Col>
              <Col sm={4}>
                {/* Перейти на товар на сайті */}
                <Link to={`${cabinetLink}/product/${data.id}`} className="mb-3" target='_blank'>
                  <CButton className="mb-3" color="secondary" variant="outline" title="Перейти на товар на сайті">Перейти на сайт</CButton>
                </Link>
              </Col>
            </Row>
            <hr style={{ marginTop: '55px', marginBottom: '55px' }} />
          </>
        }

        <Tab.Container id="left-tabs-example" defaultActiveKey="info">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="img">Зображення</Nav.Link>
                  </Nav.Item>
                }
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="show">Наявність</Nav.Link>
                  </Nav.Item>
                }
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="price">Ціни</Nav.Link>
                  </Nav.Item>
                }
                <Nav.Item>
                  <Nav.Link eventKey="info">Загальні</Nav.Link>
                </Nav.Item>
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="product_info">Інформація про товар</Nav.Link>
                  </Nav.Item>
                }
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="attributes">Атрибути</Nav.Link>
                  </Nav.Item>
                }
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="category">Категорії</Nav.Link>
                  </Nav.Item>
                }
                {
                  (data != undefined) &&
                  <Nav.Item>
                    <Nav.Link eventKey="releted">Супутні товари</Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="img">
                  {/* Додавання зображення */}
                  {
                    (data != undefined) &&
                    <ProductMedia
                      data={data} MyData={MyData} setMyData={setMyData}
                      LoaderProductsUpdate={LoaderProductsUpdate} toastifySuccess={popup.toastifySuccess}
                    />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="show">
                  {/* Наявність */}
                  {
                    (data != undefined) &&
                    <ProductAvailability handleSubmit={handleSubmit} data={data} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="price">
                  {/* Ціна */}
                  {
                    (data != undefined) &&
                    <ProductPrice handleSubmit={handleSubmit} data={data} MyData={MyData} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="info">
                  {/* Загальна Інформація про товар */}
                  <ProductGeneral data={data} MyData={MyData} handleSubmit={handleSubmit} />
                </Tab.Pane>
                <Tab.Pane eventKey="product_info">
                  {
                    (data != undefined) ?
                      <>
                        <div className='row'>
                          <div className='col'> <h3>Інформація про товар</h3></div>
                          <div className='col d-flex justify-content-end'>
                            <BasicModal
                              show={showModal}
                              actionShowModal={actionShowModal}
                              title={`Додати інформацію про товар`}
                              content={
                                <AddProductInfo
                                  actionShowModal={actionShowModal}
                                  product={data.id}
                                  toastifySuccess={popup.toastifySuccess}
                                  toastifyWarning={popup.toastifyWarning}
                                />
                              }
                            />
                          </div>
                        </div>

                        <ViewProductInfo
                          product={data.id}
                          update={update}
                          showModal={showModal}
                          setUpdate={setUpdate}
                          actionShowModal={actionShowModal}
                          popup={popup} />
                      </>
                      : ''
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="attributes">
                  {
                    (data != undefined) &&
                    <ProductAttributes data={data} productId={data.id} attributeItems={data.attributeItems} toastifySuccess={popup.toastifySuccess} type="products" />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="category">
                  {
                    (data != undefined) &&
                    <ProductCategories product_id={data.id} toastifySuccess={popup.toastifySuccess} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="releted">
                  {
                    (data != undefined) && <ProductRelated product={data} />
                  }
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container >
      </div>

      {/* <hr style={{ marginTop: '55px', marginBottom: '55px' }} /> */}
      {/* Атрибути */}
      {/* <ProductAttributes productId={data.id} attributeItems={data.attributeItems} toastifySuccess={popup.toastifySuccess} type="products" /> */}
      {/* <hr style={{ marginTop: '55px', marginBottom: '55px' }} /> */}
      {/* Категорії */}
      {/* {(MyData != undefined) ? <ProductCategories product_id={MyData.id} toastifySuccess={popup.toastifySuccess} /> : ''} */}
      {/* <hr style={{ marginTop: '55px', marginBottom: '55px' }} /> */}
    </>
  );
};

export default ProductForm;