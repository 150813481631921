import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const urlApi = '/languages';

const LangugesPanel = ({product}) => {
    const navigate = useNavigate();

    // console.log(product);
    const [languagesList, setLaguagesList] = useState([]);
    const [language, setLaguage] = useState('');
    // const [language, setLaguage] = useState((product != undefined)? product.language.key: 'ua');
    const [translations, setTranslations] = useState((product != undefined && product.translations != undefined)? product.translations: false);

    const getLangs  = () =>{
        AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
            setLaguagesList(response.data['hydra:member'])
        })
    }

    useEffect(() =>  {
        setLaguage((product.language != undefined)? product.language.name: 'ua');
        getLangs();
    }, [product]);


    const CreateTranslate  = (e, l) => {
        
        const data_response = {
            name: product.name,
            description: product.description,
            code1c: product.code1c,
            barcode: product.barcode,
            status: product.status,
            price: product.price,
            discounts: product.discounts,
            top: product.top,
            show: product.show,
            article: product.article,
            nds: product.nds,
            packaging: product.packaging,
            productToOrder: product.productToOrder,
            active: product.active,
          };
        data_response['language'] = `/api/languages/${l.id}`;
        data_response['translation'] = `/api/products/${product.id}`;
        const response = AxiosInstance.post(`/products`, data_response).then((response) => {
            if (response.status === 201) {
              return navigate(`/products/update/${response.data.id}`);
            }
          });
    }

    return (
        <div>
            {/* <div>: </div> */}
            <div>
                <ul>
                    {
                        (languagesList.langht > 0) ? 
                            (product.translation != undefined && product.translation.id !=  undefined) ?
                                <>
                                    <h4>Переклад: {language}</h4>
                                    <Link to={`/products/update/${product.translation.id}`}>Повернутися до UA</Link>
                                </>
                            :
                                <>
                                    <h4>Переклад: {language}</h4>
                                    {/* <li className='btn btn-secondary text-white'>{language}</li> */}
                                    {
                                        languagesList.map((l) =>{
                                            let search = true;
                                            console.log('translations', translations);
                                            if(l.name != language){
                                                if(translations != false){
                                                    let t = translations.filter(t => t.language.key === l.key)
                                                    if(t.length > 0)
                                                        return (<Button variant="outline-secondary ml-5" ><Link to={`/products/update/${t[0].id}`} >{l.key}</Link></Button>);
                                                    else
                                                        search = false;
                                                }else{
                                                    search = false;
                                                }
                                            }
                                            if(!search)
                                                return (<Button 
                                                    variant="outline-secondary mr-5"
                                                    onClick={(e) => {CreateTranslate(e, l)}}
                                                >
                                                    додати  <b>{l.key}</b>
                                                </Button>);
                                        })
                                    }
                                </>
                        :
                            " "
                    }
                </ul>
            </div>
        
        </div>
    )
}

export default LangugesPanel;