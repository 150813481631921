import React, { useRef, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import FilterEvents from 'src/components/filter/FilterEvents';
import FilterLocations from 'src/components/filter/FilterLocations';
import { confirmAlert } from 'react-confirm-alert';

const page = 1;
const pageSize = 30;
const urlApi = '/subscribers';
const urlRouter = '/subscribers';

const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));

export async function LoaderSubscribers({ params }) {
  let query = `page=${page}`;
  if (role == 'ROLE_MANAGER')
    query += `&accounts.manager.id=${userData.id}`;
  try {
    const response = await AxiosInstance.get(`${urlApi}?${query}&itemsPerPage=${pageSize}`)
    return response;
  } catch (error) {
    console.error("Помилка запиту за учасниками", error);
    return { data: { 'hydra:member': [], 'hydra:totalItems': 0 } }
  };
};

const roleList = {
  ROLE_ADMIN: 'Адміністратор',
  ROLE_MANAGER: 'Менеджер',
  ROLE_CLIENT: 'Клієнт',
  ROLE_USER: 'Без ролі',
};

const Subscribers = ({ user_data }) => {
  if (!Permissions(user_data, 'Subscriber', 'VIEW_SUBSCRIBER')) {
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const localeText = { noRowsLabel: 'Немає даних' };
  const [eventFilter, setEventFilter] = useState(null);
  const [eventClear, setEventClear] = useState(false);
  const [locationFilter, setLocationFilter] = useState(null);
  const [locationClear, setLocationClear] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const isFirstRender = useRef(true);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextDeleteSuccess = "Учасника видалено";
  const popupTextDeleteWarning = "Учасника не видалено";

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch || eventFilter || locationFilter) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  }, [nameFilterSearch, eventFilter, locationFilter]);

  // Запит за попередніми замовленнями (+фільтр)
  const getRowsTable = async (p = 1) => {
    setShowLoader(true);
    let request = ``;

    if (nameFilterSearch) {
      request += `&name=${nameFilterSearch}`;
    };
    if (eventFilter && eventFilter.value) {
      request += `&events.id=${eventFilter.value}`;
    };
    if (locationFilter && locationFilter.value) {
      request += `&location.id=${locationFilter.value}`;
    };
    // if (role == 'ROLE_MANAGER')
    //   request += `&accounts.manager.id=${userData.id}`;

    // console.log("getRowsTable request", request);

    try {
      const response = await AxiosInstance.get(`${urlApi}?page=${p}${request}&itemsPerPage=${pageSize}`);
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
      if (request !== "") {
        setIsActiveFilter(true);
      };
    } catch (error) {
      console.error("Помилка запиту за учасниками", error);
    } finally {
      setShowLoader(false);
    };
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = event => {
    setNameFilterSearch(event.target.value);
  };

  // ----- End handleFilterChange -----

  // Фільтрування по name
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setEventClear(true);
    setEventFilter(null);
    setLocationClear(true);
    setLocationFilter(null);
    setIsActiveFilter(false);
  };

  // Після скидання фільтру зробити запит за учасниками
  useEffect(() => {
    // Зробити відмітку, що вже не перший рендер і щоб в подальшому відбувався запит буз врахування фільтрів
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    };

    // Зробити запит після скидання фільтру
    if (!isActiveFilter) {
      setEventClear(false);
      setLocationClear(false);
      getRowsTable();
    };
  }, [isActiveFilter]);

  // Функція для видалення учасника
  const deleteSubscribers = async (id) => {
    try {
      await AxiosInstance.delete(`${urlApi}/${id}`);
      // Popup про успішні зміни
      popup.toastifySuccess(popupTextDeleteSuccess);
      getRowsTable();
    } catch (error) {
      console.error("Помилка запиту для видалення учасника", error);
      popup.toastifyWarning(popupTextDeleteWarning);
    };
  };

  // Повідомлення для підтвердження на видалення учасника
  function Deleted(row) {
    confirmAlert({
      title: `Ви впевнені що хочите видалити цей пункт (${row.name})?`,
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            // console.log('Видатити учасника row', row);
            deleteSubscribers(row.id);
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  // Функція для зміни формату номера для кращої читабельності
  const formatPhoneNumber = (phone) => {
    if (!phone || phone.length != 12) return phone;

    // Форматуємо номер в формат "+38 (999) 999-99-99"
    const formattedPhone = `+${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`;

    return formattedPhone;
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'name', headerName: 'Учасник (ПІБ)', width: 300,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "#0d6efd", cursor: "pointer" }} title="Редагувати учасника">
            <span style={{ textDecoration: "none", color: "#0d6efd" }}>{params.row.name}</span>
          </Link>
        )
      }
    },
    {
      field: 'phone', headerName: 'Телефон', width: 150,
      renderCell: (params) => {
        return formatPhoneNumber(params.row.phone)
      }
    },
    { field: 'email', headerName: 'Емейл', width: 200 },
    {
      field: 'location', headerName: 'Підрозділ', width: 180,
      renderCell: (params) => {
        return params.row.location.name;
      }
    },
    {
      field: 'events', headerName: 'Подія', width: 330,
      renderCell: (params) => {
        return (
          <div>
            {params.row.events.map((event) => {
              return (
                <div key={event.id} title={event.name}>
                  {event.name}
                </div>
              )
            })}
          </div>
        )
      }
    },
    {
      field: 'specialties', headerName: 'Напрямки', width: 250,
      renderCell: (params) => {
        return (
          <div style={{ fontSize: "11px", lineHeight: "1" }}>
            <div style={{ fontSize: "11px" }}>
              <strong style={{ color: "#000" }}>{params.row?.specialty?.name}</strong>
            </div>
            {
              params.row.specialties.map((specialty) => {
                return (
                  <div key={specialty.id}>
                    {specialty.name}
                  </div>
                )
              })
            }
          </div >
        )
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/update/${params.row.id}`}>
            <CButton color="dark" variant="outline" title="Редагувати учасника">
              <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
            </CButton>
          </Link>
        );
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      width: 85,
      renderCell: (params) => {
        return (
          <CButton color="danger" variant="outline" onClick={() => { Deleted(params.row) }} title="Видалити учасника">
            <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
          </CButton>
        );
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion key={activeItem} activeItemKey={activeItem} flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="ПІБ" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <FilterEvents setData={setEventFilter} clear={eventClear} />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <FilterLocations setData={setLocationFilter} clear={locationClear} />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled} title="Виконати пошук">Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset} title="Очистити фільтр">X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Учасники</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary" title="Створити учасника"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[pageSize]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
                loading={showLoader}
                localeText={localeText}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Subscribers;