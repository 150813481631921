import { DataGrid } from '@mui/x-data-grid';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';
import MyToastify from 'src/components/myComponents/MyToactify';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import FilterLocations from 'src/components/filter/FilterLocations';

// Список учасників події
const EventsSubscribersList = (props) => {
    // console.log('EventsSubscribersList props', props);

    const urlApi_1 = "/subscribers";
    const page = 1;
    const pageSize = 30;

    const [rows, setRows] = useState([]);
    const [rowCountState, setRowCountState] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const localeText = { noRowsLabel: 'Немає даних' };
    const [nameFilterSearch, setNameFilterSearch] = useState('');
    // Заблокувати кнопку фільрації якщо не введені символи для пошуку
    const [disabled, setDisabled] = useState(true);
    const [locationFilter, setLocationFilter] = useState(null);
    const [locationClear, setLocationClear] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const isFirstRender = useRef(true);

    // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
    const activeAccordionItem = useOpenAccordion();
    const activeItem = activeAccordionItem.activeItem;

    // Викликає popup з власного компонента
    const popup = MyToastify();
    // Текст для popup
    const popupTextDeleteSuccess = "Учасника видалено";
    const popupTextDeleteWarning = "Учасника не видалено";

    // Функція для запиту за підписниками на подію
    const getRowsTable = async (id, p = 1) => {
        setShowLoader(true);
        let request = ``;
        if (nameFilterSearch != '') {
            request += `&name=${nameFilterSearch}`;
        };
        if (locationFilter && locationFilter.value) {
            request += `&location.id=${locationFilter.value}`;
        };
        try {
            const response = await AxiosInstance.get(`${urlApi_1}?events.id=${id}&page=${p}&itemsPerPage=${pageSize}${request}`,
                //  { headers: { 'accept': 'application/json' } }
            );
            // console.log('subscribers get response', response);
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
            if (request !== "") {
                setIsActiveFilter(true);
            };
        } catch (error) {
            console.error("Помилка запиту за підписниками на подію", error);
        } finally {
            setShowLoader(false);
        };
    };

    // Функція для зміни сторінки
    const handlePageChange = (page) => {
        getRowsTable(props.eventId, page + 1);
    };

    // Зробити запит за підписниками
    useEffect(() => {
        if (props.eventId) {
            getRowsTable(props.eventId);
        };
    }, []);

    // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
    useEffect(() => {
        if (nameFilterSearch || locationFilter) {
            setDisabled(false);
        } else {
            setDisabled(true);
        };
    }, [nameFilterSearch, locationFilter]);

    // ----- Start handleFilterChange -----
    const handleNameFilterChange = event => {
        setNameFilterSearch(event.target.value);
    };

    // ----- End handleFilterChange -----

    // Фільтрування по name
    const handleFilterSearch = () => {
        getRowsTable();
    };

    // Скинути фільтр
    const handleFilterReset = () => {
        setNameFilterSearch("");
        setLocationClear(true);
        setLocationFilter(null);
        setIsActiveFilter(false);
    };

    // Після скидання фільтру зробити запит за учасниками
    useEffect(() => {
        // Зробити відмітку, що вже не перший рендер і щоб в подальшому відбувався запит буз врахування фільтрів
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        };

        // Зробити запит після скидання фільтру
        if (!isActiveFilter) {
            setLocationClear(false);
            getRowsTable();
        };
    }, [isActiveFilter]);

    // Функція для зміни формату номера для кращої читабельності
    const formatPhoneNumber = (phone) => {
        if (!phone || phone.length != 12) return phone;

        // Форматуємо номер в формат "+38 (999) 999-99-99"
        const formattedPhone = `+${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`;

        return formattedPhone;
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 30 },
        {
            field: 'name', headerName: 'Учасник', width: 300,
            renderCell: (params) => {
                return (
                    <span onClick={() => props.setSubscriberId(params.row.id)} title="Редагувати учасника" style={{ textDecoration: "none", color: "#0d6efd", cursor: "pointer" }}>
                        {params.row.name}
                    </span>
                );
            }
        },
        {
            field: 'phone', headerName: 'Телефон', width: 150,
            renderCell: (params) => {
                return formatPhoneNumber(params.row.phone)
            }
        },
        { field: 'email', headerName: 'Емейл', width: 200 },
        {
            field: 'location', headerName: 'Підрозділ', width: 180,
            renderCell: (params) => {
                return params.row.location.name;
            }
        },
        {
            field: 'specialties', headerName: 'Напрямки', width: 250,
            renderCell: (params) => {
                return (
                    <div style={{ fontSize: "11px" }}>
                        <div style={{ fontSize: "11px" }}>
                            <strong style={{ color: "#000" }}>{params.row?.specialty?.name}</strong>
                        </div>
                        {
                            params.row.specialties.map((specialty) => {
                                return (
                                    <div key={specialty.id}>
                                        {specialty.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 60,
            renderCell: (params) => {
                return (
                    <CButton color="dark" variant="outline" onClick={() => props.setSubscriberId(params.row.id)} title="Редагувати учасника">
                        <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
                    </CButton>
                );
            }
        },
        {
            field: "delete",
            headerName: "Видалити",
            sortable: false,
            width: 85,
            renderCell: (params) => {
                return (
                    <CButton color="danger" variant="outline" onClick={() => { Deleted(params.row) }} title="Видалити учасника">
                        <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                    </CButton>
                );
            }
        },
    ];

    // Функція для видалення учасника
    const deleteSubscribers = async (id) => {
        try {
            await AxiosInstance.delete(`${urlApi_1}/${id}`);
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextDeleteSuccess);
            getRowsTable(props.eventId);
        } catch (error) {
            console.error("Помилка запиту для видалення учасника", error);
            popup.toastifyWarning(popupTextDeleteWarning);
        };
    };

    // Повідомлення для підтвердження на видалення учасника
    function Deleted(row) {
        confirmAlert({
            title: `Ви впевнені що хочите видалити цей пункт (${row.name})?`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        // console.log('Видатити учасника row', row);
                        deleteSubscribers(row.id);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ],
            // Задайте користувацький контейнер, наприклад, root для збереження порядку рендерингу
            parentElement: document.body,
        });
    };

    // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
    const keyDown = (event) => {
        // Виконати пошук
        if (event.key === 'Enter' && !disabled) {
            event.preventDefault();
            handleFilterSearch(event);
        };
        // Скинути пошук
        if (event.key === 'Escape') {
            event.preventDefault();
            handleFilterReset(event);
        };
    };

    return (
        <div>
            <CCard className="mb-2">
                <CAccordion key={activeItem} activeItemKey={activeItem} flush>
                    <CAccordionItem itemKey={1}>
                        <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                        <CAccordionBody>
                            <CRow className="my-3 mx-2">
                                <CCol md={9} className="me-auto">
                                    <CRow>
                                        <CCol md={4} className='filter-field'>
                                            <CFormInput
                                                placeholder="ПІБ" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                                                onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                                            />
                                        </CCol>
                                        <CCol md={3} className='filter-field'>
                                            <FilterLocations setData={setLocationFilter} clear={locationClear} />
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol md={3} style={{ textAlign: "end" }}>
                                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                                </CCol>
                            </CRow>
                        </CAccordionBody>
                    </CAccordionItem>
                </CAccordion>

                <div className="mt-2" style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        pagination
                        rowCount={rowCountState}
                        loading={showLoader}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        localeText={localeText}
                    />
                </div>
            </CCard>

            <CButton className="mt-2" color="secondary" onClick={() => props.setIsShowModalList(false)}>Закрити</CButton>
        </div>
    );
};

export default EventsSubscribersList;